@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans 3', sans-serif;
}

.app {
    display: flex;
    position: relative;
}
