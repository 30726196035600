.jsonBlockOuter {
    overflow: auto;
    padding: 10px;
    margin: 10px;
    background-color: #f0f0f0;
    border: #c9c9c9 1px solid;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardJson {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fcfcfc;
    border: #c9c9c9 1px solid;
}
